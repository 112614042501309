<template>
  <div class="row g-4" ref="masonryLayout" v-if="isShowAllArray">
    <div class="col-12 col-md-6"
         v-for="(item, inx) in isShowAllArray" :key="item.id">
      <masonry-panel :panel-number="item.id"
                     :panel-title="item.name"
                     :panel-list="item.list"
                     :panel-class="{'_highlight': inx === 0}"
      />
    </div>
    <div class="col-12">
      <button class="masonry-btn" v-if="isWindowWidth" @click.prevent="showAll = !showAll">
        {{ showAll ? 'Скрыть' : 'Показать все услуги' }}
      </button>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import Masonry from "masonry-layout"
import MasonryPanel from "./MasonryPanel";

export default {
  name: "MasonryLayout",
  components: {MasonryPanel},
  data() {
    return {
      masonry: null,
      showAll: true,
      window: {
        width: 0,
      }
    }
  },
  props: {
    masonryData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  setup() {
    const masonryLayout = ref(null);

    // onMounted(() => new Masonry(masonryLayout.value, {
    //   percentPosition: true
    // }))

    return {masonryLayout};
  },
  mounted() {
    this.initMasonry();
  },
  activated() {
    this.initMasonry();
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.initMasonry();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    initMasonry() {
      if (this.window.width >= 768) {
        this.masonry = new Masonry(this.masonryLayout, {
          percentPosition: true
        })
      } else {
        this.showAll = false;
      }
    }
  },
  computed: {
    isShowAllArray() {
      return this.showAll ? this.masonryData : this.masonryData.slice(0, 3);
    },
    isWindowWidth() {
      return this.window.width <= 768;
    }
  }
}
</script>
<style lang="scss" scoped>
.masonry-btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #393CEA;
  padding: 19px 45px;
  background: #F5F5FE;
  border-radius: 27.5px;
  border: none;
  width: 100%;
  margin-top: 48px;
}
</style>