<template>
  <swiper
      :navigation="navigation"
      :pagination="pagination"
      :breakpoints='{
        "0": {
          "slidesPerView": 1,
          "pagination": true,
        },
        "768": {
          "slidesPerView": 2,
          "spaceBetween": 30
        }}'>
    <swiper-slide v-for="slide in carouselData" :key="slide.id">
      <carousel-slide :slide-name="slide.name" :slide-text="slide.preview" :slide-link="slide.url"/>
    </swiper-slide>
  </swiper>
  <div class="carousel-control">
    <div class="carousel-pagination"/>
    <div class="carousel-navigation">
      <div class="carousel-button carousel-button-prev"/>
      <div class="carousel-button carousel-button-next"/>
    </div>
  </div>
</template>

<script>
import CarouselSlide from "./CarouselSlide";
import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperCore, {Navigation, Pagination} from "swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

SwiperCore.use([Navigation, Pagination]);

export default {
  name: "CarouselLayout",
  components: {CarouselSlide, Swiper, SwiperSlide,},
  data() {
    return {
      pagination: {
        clickable: true,
        el: '.carousel-pagination',
        type: 'bullets',
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },
      },
      navigation: {
        nextEl: '.carousel-button-next',
        prevEl: '.carousel-button-prev',
      },
    }
  },
  props: {
    carouselData: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
  },
}
</script>
<style lang="scss">
.swiper-container {
  padding: 0 0 30px 0;
  @include _768 {
    padding: 0 0 48px 0;
  }
}

.carousel-control {
  display: flex;
  align-items: center;
  justify-content: center;
  @include _768 {
    justify-content: space-between;
  }
}

.carousel-pagination {
  .swiper-pagination-bullet {
    width: 9px;
    height: 9px;
    background: #E0E0FF;
    opacity: 1;
    transition: all .3s ease-in;
    border-radius: 27.5px;
    margin: 0 14px 0 0;

    &.swiper-pagination-bullet-active {
      width: 69px;
      background: #7476F0;
    }
  }
}

.carousel-navigation {
  display: none;
  width: 90px;
  align-items: center;
  justify-content: space-between;
  @include _768 {
    display: flex;
  }

  .carousel-button {
    width: 36px;
    height: 36px;
    background-color: #F0F0FF;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: background-color .3s ease-in;

    &:hover {
      background-color: #DCDCFF;
    }

    &:before {
      content: '';
      width: 9px;
      height: 16px;
      background: url("../../assets/carousel-button.svg") no-repeat center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.carousel-button-prev {
      transform: rotate(180deg);
    }
  }
}
</style>