<template>
  <div class="home">
    <div class="section-page" id="about-us">
      <div class="container">
        <div class="section-title">О НАШЕЙ КОМПАНИИ</div>
        <about-us/>
      </div>
    </div>
    <div class="section-page" id="basic-services">
      <div class="container">
        <div class="section-title">ОСНОВНЫЕ УСЛУГИ</div>
        <masonry-layout :masonry-data="basicServices"/>
      </div>
    </div>
    <div class="section-page _bg">
      <div class="container">
        <div class="section-title">НАШИ КЕЙСЫ</div>
        <carousel-layout :carousel-data="cases"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import MasonryLayout from "../components/masonry/MasonryLayout";
import CarouselLayout from "../components/carousel/CarouselLayout";
import AboutUs from "../components/AboutUs";

export default {
  name: 'Home',
  components: {
    AboutUs,
    CarouselLayout,
    MasonryLayout,
  },
  computed: {
    ...mapState({
      basicServices: state => state.basicServices.basicServices,
      cases: state => state.cases.cases,
    }),
  },
}
</script>
