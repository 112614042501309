<template>
  <div class="about-us">
    <div class="row g-4 g-md-6 align-items-center">
      <div class="col-12 col-sm-6 col-md-5">
        <picture>
          <source media="(max-width: 576px)" :srcset="require('@/assets/about-us-logo-1.svg')"/>
          <img class="img-fluid w-100" :src="require('@/assets/about-us-logo-2.svg')"/>
        </picture>
      </div>
      <div class="col-12 col-sm-6 col-md-7">
        Мы — команда профессионалов в области корпоративных услуг и безопасности, корпоративной аналитики, корпоративных и
        финансовых расследований, юридических услуг, PR компаний и собственников бизнеса, а также в области
        антикризисных
        мероприятий.
      </div>
      <div class="col-12">
        SBS оказывает содействие в получении необходимой информации при заключении сделок, проверки контрагентов,
        проведении внутренних расследований, осуществляет аналитику собранных данных и даёт рекомендации по принятию
        решений и реагированию, защищает интересы компаний и собственников бизнеса в судах и в правоохранительных
        органах.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style lang="scss" scoped>
.about-us {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  text-align: justify;
  color: #454545;
  @include _768 {
    font-size: 24px;
    line-height: 48px;
  }
}
</style>