<template>
  <div class="carousel-slide">
    <div class="carousel-title" v-html="slideName"/>
    <div class="carousel-text" v-html="slideText"/>
    <button-link class="carousel-link"
                 type-link="router"
                 :button-link="{ name: 'case', params: { url: slideLink } }">
      Подробнее
    </button-link>
  </div>
</template>

<script>
import ButtonLink from "../ButtonLink";

export default {
  name: "CarouselSlide",
  components: {ButtonLink},
  props: {
    slideName: {
      type: String,
      default: 'slideName',
    },
    slideText: {
      type: String,
      default: 'slideText',
    },
    slideLink: {
      type: String,
      default: 'slideLink',
    },
  }
}
</script>

<style lang="scss">
.swiper-wrapper {
  .swiper-slide {
    height: auto;
    background: #6163EE;
    border-radius: 16px;
    padding: 24px 30px 36px;
    color: #FFFFFF;

    &.swiper-slide-active {
      background: #393CEA;
    }
  }
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  height: 100%;

  .carousel-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 24px 0;
    @include _768 {
      font-size: 36px;
      line-height: 42px;
    }
  }

  .carousel-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 64px 0;
    @include _768 {
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 36px 0;
    }
  }

  .carousel-link {
    margin: auto auto 0 0;
  }
}
</style>