<template>
  <card-element :class="['masonry-panel', panelClass, `count-${panelNumber}`]">
    <div class="masonry-count" v-if="panelNumber" :style="countElemStyle"/>
    <div class="masonry-icon" :class="{'_secondary' : panelNumber === '01'}"/>
    <div class="masonry-content">
      <div class="masonry-title" v-if="panelTitle" v-html="panelTitle"/>
      <list-element v-if="panelList.length > 0"
                    :list-data="panelList"
                    :class="['masonry-list', panelClass]"
      />
    </div>
  </card-element>
</template>

<script>
import CardElement from "../CardElement";
import ListElement from "../ListElement";

export default {
  name: "MasonryPanel",
  components: {ListElement, CardElement},
  computed: {
    imgUrl() {
      return require(`@/assets/masonry/masonry-${this.panelNumber}.svg`);
    },
    countElemStyle() {
      return {'background': `url(${this.imgUrl}) no-repeat`};
    }
  },
  props: {
    panelNumber: {
      type: String,
      default: '00',
    },
    panelTitle: {
      type: String,
      default: 'Title',
    },
    panelList: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    panelClass: {
      type: [String, Array, Object],
      default() {
        return [];
      },
    },
  }
}
</script>
<style lang="scss" scoped>
.masonry-panel {
  background: #FFFFFF;
  position: relative;
  @include _768 {
    padding: 40px 48px;
  }

  &._highlight {
    background: rgba(57, 60, 234, 0.8);

    .masonry-title {
      color: #FFFFFF;
    }

    .masonry-count {
      height: 106px;
      width: 142px;
      @include _768 {
        height: 142px;
        width: 191px;
      }
    }
  }

  &.count-01:before {
    content: '';
    position: absolute;
    right: 24px;
    top: 156px;
    background: url("../../assets/polygon/pol-1.svg") no-repeat center;
    background-size: contain;
    width: 53px;
    height: 92px;
    @include _768 {
      right: 48px;
      top: 218px;
      width: 91px;
      height: 159px;
    }
  }

  &.count-02:before,
  &.count-04:before,
  &.count-06:before,
  &.count-07:before,
  &.count-08:before {
    content: '';
    position: absolute;
    right: 24px;
    top: 82px;
    background: url("../../assets/polygon/pol-2.svg") no-repeat center;
    background-size: contain;
    width: 42px;
    height: 74px;
    @include _768 {
      background: url("../../assets/polygon/pol-3.svg") no-repeat center;
      background-size: contain;
      top: 146px;
      right: 48px;
      width: 15px;
      height: 121px;
    }
  }

  &.count-03:before,
  &.count-05:before,
  &.count-10:before {
    content: '';
    position: absolute;
    right: 24px;
    top: 82px;
    background: url("../../assets/polygon/pol-4.svg") no-repeat center;
    background-size: contain;
    width: 42px;
    height: 40px;
    @include _768 {
      background: url("../../assets/polygon/pol-5.svg") no-repeat center;
      background-size: contain;
      top: auto;
      bottom: 44px;
      right: 48px;
      width: 15px;
      height: 14px;
    }
  }

  &.count-09:before{
    content: '';
    position: absolute;
    right: 24px;
    top: 82px;
    background: url("../../assets/polygon/pol-4.svg") no-repeat center;
    background-size: contain;
    width: 42px;
    height: 40px;
    @include _768 {
      background: url("../../assets/polygon/pol-6.svg") no-repeat center;
      background-size: contain;
      top: 146px;
      right: 48px;
      width: 15px;
      height: 68px;
    }
  }
}

.masonry-content {
  position: relative;
  z-index: 5;
  max-width: 430px;
}

.masonry-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #252525;
  @include _768 {
    font-size: 24px;
    line-height: 32px;
  }
}

.masonry-list {
  margin-top: 30px;
}

.masonry-icon {
  width: 40px;
  height: 40px;
  background: rgba(57, 60, 234, 0.8);
  border-radius: 16px;
  position: relative;
  margin: 0 0 20px 0;
  @include _768 {
    width: 60px;
    height: 60px;
    margin: 0 0 24px 0;
  }

  &:before {
    content: '';
    width: 19px;
    height: 19px;
    background: url('../../assets/masonry-icon-1.svg') no-repeat center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include _768 {
      width: 29px;
      height: 29px;
    }
  }

  &._secondary {
    background: #FFFFFF;

    &:before {
      background: url('../../assets/masonry-icon-2.svg') no-repeat center;
      background-size: contain;
    }
  }
}

.masonry-count {
  position: absolute;
  right: 20px;
  top: 24px;
  background-position: center !important;
  background-size: contain !important;
  height: 42px;
  width: 69px;

  @include _768 {
    right: 40px;
    top: 40px;
    height: 70px;
    width: 117px;
  }
}
</style>