<template>
  <ul class="list-elem" v-if="listData">
    <li class="list-item"
        v-for="(li, index) in listData" :key="index">
      {{ li }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "ListElement",
  props: {
    listData: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
  }
}
</script>
<style lang="scss" scoped>
.list-elem {
  list-style: none;
  padding: 0;
  margin: 0;

  .list-item {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #454545;
    margin: 0 0 20px 0;
    padding: 0 0 0 30px;
    @include _768 {
      font-size: 18px;
      line-height: 28px;
      margin: 0 0 30px 0;
      padding: 0 0 0 40px;
    }

    &:before {
      content: '';
      background: url("../assets/list-item-1.svg") no-repeat center;
      background-size: contain;
      width: 13px;
      height: 10px;
      position: absolute;
      top: 7px;
      left: 0;
      @include _768 {
        width: 20px;
        height: 15px;
      }
    }
  }
  &._highlight {
    .list-item {
      color: #FFFFFF;
      &:before {
        background: url("../assets/list-item-2.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
}
</style>
